import { gql, useMutation } from "@apollo/client";
import {
  AgreementDocument_AgreementDocumentFragment,
  AgreementDocumentPromptType,
  RespondToAgreementMutation,
  RespondToAgreementMutationVariables,
} from "@generated/graphql";
import {
  AutoWidthIFrame,
  Button,
  ErrorBox,
  triggerErrorToast,
  triggerSuccessToast,
} from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { useState } from "react";
import { AgreementPrompts } from "./components/AgreementPrompts";
import { CHECKBOX_TRUE } from "./constants";
import { responseStateBuilder } from "./utils";

const RESPOND_TO_AGREEMENT = gql`
  mutation RespondToAgreement($input: AgreementResponseInput!) {
    respondToAgreement(input: $input) {
      id
      signedAt
    }
  }
`;

AgreementDocument.fragments = {
  agreementDocument: gql`
    fragment AgreementDocument_AgreementDocument on AgreementDocument {
      fileName
      revision
      title
      campaignStartDate
      prompts {
        ...PromptStateBuilder_AgreementDocumentPrompt
        ...AgreementPrompts_AgreementDocumentPrompt
      }
    }
    ${responseStateBuilder.fragments.agreementDocumentPrompt}
    ${AgreementPrompts.fragments.agreementDocumentPrompt}
  `,
};

type Props = {
  agreementDocument: AgreementDocument_AgreementDocumentFragment;
  markCompleted: () => void;
};

export function AgreementDocument({ agreementDocument, markCompleted }: Props) {
  const { user } = useAuth();
  const userFullName = user?.fullName;

  const [responseState, setResponseState] = useState(
    responseStateBuilder(agreementDocument.prompts)
  );
  const onResponseStateChange = (index: number, value: string) => {
    setResponseState((prev) =>
      prev.map((response, i) =>
        i === index ? { ...response, value, error: null } : response
      )
    );
  };

  const [respondToAgreement, { loading, error }] = useMutation<
    RespondToAgreementMutation,
    RespondToAgreementMutationVariables
  >(RESPOND_TO_AGREEMENT, {
    variables: {
      input: {
        documentFileName: agreementDocument.fileName,
        documentRevision: agreementDocument.revision,
        responses: responseState.map(({ index, value }) => ({
          index,
          value: value.trim(),
        })),
      },
    },
    onCompleted: ({ respondToAgreement: { signedAt } }) => {
      triggerSuccessToast({
        message: `Response to ${agreementDocument.title} submitted successfully!`,
        sub: new Date(signedAt).toLocaleString(),
      });
      markCompleted();
    },
    onError: (error) =>
      triggerErrorToast({
        message: "We could not process your response.",
        sub: "Please contact the TbT Team.",
        log: error,
      }),
  });

  const validateInputs = (): boolean => {
    let validationSuccessful = true;

    const updatedResponseState = responseState.map((response) => {
      const prompt = agreementDocument.prompts.find(
        ({ index }) => index === response.index
      );
      if (!prompt) return response;

      if (prompt.type === AgreementDocumentPromptType.Checkbox) {
        if (prompt.required && response.value !== CHECKBOX_TRUE) {
          validationSuccessful = false;
          return { ...response, error: "Your acceptance is required." };
        }
      } else if (prompt.type === AgreementDocumentPromptType.Signature) {
        if (prompt.required) {
          if (response.value.trim() === "") {
            validationSuccessful = false;
            return { ...response, error: "Your signature is required." };
          }

          if (
            userFullName !== undefined &&
            userFullName.trim().toLowerCase() !==
              response.value.trim().toLowerCase()
          ) {
            validationSuccessful = false;
            return {
              ...response,
              error: `Please sign as "${userFullName}".`,
            };
          }
        }
      }

      return response;
    });

    setResponseState(updatedResponseState);
    return validationSuccessful;
  };

  const onSubmitResponse = async () => {
    if (!validateInputs()) return;
    await respondToAgreement();
  };

  return (
    <div className="flex flex-col w-full gap-y-8">
      <AutoWidthIFrame
        src={`/agreements/${encodeURIComponent(
          agreementDocument.fileName
        )}/${encodeURIComponent(agreementDocument.revision)}/agreement.html`}
        title={agreementDocument.title}
        height={450}
      />
      <div className="flex flex-col gap-y-4 px-0 sm:px-16">
        <AgreementPrompts
          agreementDocumentPrompts={agreementDocument.prompts}
          responseState={responseState}
          userFullName={userFullName ?? "your name"}
          onResponseStateChange={onResponseStateChange}
        />
        <Button type="submit" loading={loading} onClick={onSubmitResponse}>
          Sign Now
        </Button>
        <ErrorBox msg={error?.message} />
      </div>
    </div>
  );
}
