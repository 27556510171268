import { sidebarTooltipStyles } from "@utils/styleStrings";
import clsx from "clsx";
import { Spinner, Tooltip } from "components/shared";
import { useSidebarData } from "contexts/SidebarDataProvider";

export const TeacherJobBoardIndicatorBadges = () => {
  const { newJobsCount, jobOffersCount, sidebarLoading } = useSidebarData();

  return (
    <div className="flex gap-x-2 pr-3">
      {sidebarLoading && <Spinner />}
      {!sidebarLoading && jobOffersCount > 0 && (
        <Tooltip
          content="Your Job Offers Count"
          tooltipProps={sidebarTooltipStyles}
        >
          <div
            className={clsx(
              "flex relative flex-center h-6 rounded-full text-xs",
              "text-orange-500 border-orange-500 border font-light text-center w-fit min-w-[24px]"
            )}
          >
            <p className="leading-none">{jobOffersCount}</p>
          </div>
        </Tooltip>
      )}
      {!sidebarLoading && newJobsCount > 0 && (
        <Tooltip content="New Jobs Count" tooltipProps={sidebarTooltipStyles}>
          <div
            className={clsx(
              "flex relative flex-center h-6 rounded-full text-xs",
              "text-white border font-light text-center w-fit min-w-[24px]"
            )}
          >
            <p className="leading-none">{newJobsCount}</p>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
