import { CheckIcon } from "@heroicons/react/solid";
import { useAmplifyAuth } from "@lib/amplify";
import { Routes } from "@utils/routes";
import { assertUnreachable } from "@utils/types";
import { Button, ErrorBox, Input, Spinner } from "components/shared";
import { LegacyLink } from "components/shared/LegacyLink";
import isEmail from "isemail";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { RiMailSendLine } from "react-icons/ri";
import { CenteredLogoContainer } from "./components/CenteredLogoContainer";

type Step = { step: "INPUT_EMAIL" } | { step: "INPUT_CODE"; email: string };

export function ForgotPasswordPage() {
  const [currentStep, setCurrentStep] = useState<Step>({
    step: "INPUT_EMAIL",
  });

  return (
    <CenteredLogoContainer headerText="Forgot your password?">
      {currentStep.step === "INPUT_EMAIL" && (
        <EmailForm
          onComplete={(email) => {
            setCurrentStep({ step: "INPUT_CODE", email });
          }}
        />
      )}

      {currentStep.step === "INPUT_CODE" && (
        <VerificationCodeForm email={currentStep.email} />
      )}

      <div className="flex flex-center mt-4">
        <LegacyLink
          href={Routes.login.href()}
          className="text-gray-600 hover:text-gray-500 text-sm"
        >
          Back to Login
        </LegacyLink>
      </div>
    </CenteredLogoContainer>
  );
}

/**
 * Email Form
 */

type Status =
  | { name: "IDLE" }
  | { name: "LOADING" }
  | { name: "SUCCESS" }
  | { name: "ERROR"; errorMsg: string };

type EmailFormInput = { email: string };
function EmailForm({ onComplete }: { onComplete: (email: string) => void }) {
  const { onForgotPassword } = useAmplifyAuth();
  const [status, setStatus] = useState<Status>({ name: "IDLE" });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailFormInput>({
    mode: "onSubmit",
    shouldUseNativeValidation: false,
  });

  const onSubmit: SubmitHandler<EmailFormInput> = async ({ email }) => {
    setStatus({ name: "LOADING" });

    const result = await onForgotPassword(email);
    if (!result.success) {
      setStatus({ name: "ERROR", errorMsg: result.message });
    } else {
      setStatus({ name: "SUCCESS" });
      onComplete(email);
    }
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-center">
        <span className="text-sm">
          Enter your email address to receive a verification code that will be
          used to reset your password.
        </span>
      </div>
      <Input
        id="email-address"
        label="Email"
        {...register("email", {
          required: {
            value: true,
            message: "Email is required.",
          },
          validate: {
            emailIsValid: (email: string) =>
              isEmail.validate(email) ? true : "Please enter a valid email.",
          },
        })}
        type="email"
        error={errors.email?.message}
      />

      <Button
        className="group relative flex items-center w-full h-10"
        type="submit"
      >
        {status.name === "LOADING" ? (
          <div className="mr-1">
            <Spinner />
          </div>
        ) : (
          <RiMailSendLine className="h-5 w-5 text-white" />
        )}

        <span className="ml-2">Send Verification Code</span>
      </Button>

      <ErrorBox msg={status.name === "ERROR" ? status.errorMsg : null} />
    </form>
  );
}

/**
 * Verification Code Form
 */

type VerificationCodeInput = {
  email: string;
  verificationCode: string;
  newPassword: string;
};

function VerificationCodeForm({ email }: { email: string }) {
  const { onForgotPasswordSubmit } = useAmplifyAuth();
  const [status, setStatus] = useState<Status>({ name: "IDLE" });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<VerificationCodeInput>({
    mode: "onSubmit",
    defaultValues: {
      email,
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit: SubmitHandler<VerificationCodeInput> = async ({
    email,
    verificationCode,
    newPassword,
  }) => {
    setStatus({ name: "LOADING" });

    const result = await onForgotPasswordSubmit({
      email,
      verificationCode,
      newPassword,
    });

    if (!result.success) {
      setStatus({ name: "ERROR", errorMsg: result.message });
    } else {
      setStatus({ name: "SUCCESS" });
    }
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-center">
        <span className="text-sm text-green-700">
          An email with a verification code has been sent to {email}.
        </span>
      </div>
      <Input
        id="email-address"
        label="Email"
        {...register("email", {
          required: {
            value: true,
            message: "Email is required.",
          },
        })}
        type="email"
        error={errors.email?.message}
        disabled
      />
      <Input
        id="verification-code"
        label="Verification code"
        {...register("verificationCode", {
          required: {
            value: true,
            message: "Please enter your verification code.",
          },
        })}
        error={errors.verificationCode?.message}
      />

      <Input
        id="new-password"
        label="New password"
        {...register("newPassword", {
          required: {
            value: true,
            message: "Please enter a new password.",
          },
        })}
        type="password"
        error={errors.newPassword?.message}
      />

      <Button
        className="group relative flex items-center w-full h-10"
        type="submit"
      >
        <ButtonBody status={status} />
      </Button>

      <StatusMsg status={status} />
    </form>
  );
}

function ButtonBody({ status }: { status: Status }) {
  switch (status.name) {
    case "IDLE":
    case "ERROR":
      return <span>Change password</span>;

    case "SUCCESS":
      return <CheckIcon className="h-5 w-5" />;

    case "LOADING":
      return <Spinner />;

    default:
      assertUnreachable(status, "status");
  }
}

function StatusMsg({ status }: { status: Status }) {
  switch (status.name) {
    case "ERROR":
      return <ErrorBox msg={status.errorMsg} />;
    case "SUCCESS":
      return (
        <div className="text-sm text-green-700 mt-1">
          Password successfully reset!
        </div>
      );
    case "IDLE":
    case "LOADING":
      return null;
    default:
      assertUnreachable(status, "status");
  }
}
