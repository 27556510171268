import { gql } from "@apollo/client";
import { NotificationCell_MissingHostNotificationFragment } from "@generated/graphql";
import { Menu } from "@headlessui/react";
import { Routes } from "@utils/routes";
import { ButtonLink, Icon, Link } from "components/shared";

NotificationCell.fragments = {
  notification: gql`
    fragment NotificationCell_MissingHostNotification on MissingHostNotification {
      id
      userId
      message
      cohortId
      meetingId
      dismissed
      meetingRoom
    }
  `,
};

type Props = {
  onDismiss: (userId: string, meetingId: string) => void;
  notification: NonNullable<NotificationCell_MissingHostNotificationFragment>;
};

export function NotificationCell({ onDismiss, notification }: Props) {
  return (
    <Menu.Item key="notification" disabled>
      <div className="flex flex-col w-full gap-y-3 p-3">
        <div className="flex w-full h-fit justify-between items-center">
          <span className="flex gap-[6px] items-center">
            <Icon icon="triangleExclamation" size={5} color="text-rose-800" />
            <div className="text-md text-gray-800 font-bold leading-none group-hover:text-rose-800">
              Missing Host
            </div>
          </span>
          <div className="flex h-fit gap-x-3 items-center justify-end">
            <ButtonLink
              height="xs"
              openInNewTab
              className="w-fit"
              href={notification.meetingRoom}
            >
              Join Meeting
            </ButtonLink>
            <Icon
              size={8}
              icon="remove"
              className="cursor-pointer"
              color="text-slate-600 hover:text-rose-700"
              onClick={() =>
                onDismiss(notification.userId, notification.meetingId)
              }
            />
          </div>
        </div>

        <div className="flex text-sm text-gray-600 font-light gap-x-2">
          <span className="flex flex-1 w-full items-center justify-start flex-wrap gap-x-1 pl-3">
            {notification.message}{" "}
            <Link
              className="text-blue-600"
              route={Routes.cohort.details}
              routeProps={[notification.cohortId]}
            >
              Visit cohort for details.
            </Link>
          </span>
        </div>
      </div>
    </Menu.Item>
  );
}
