import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { useAuth } from "contexts/AuthProvider";
import { SIDEBAR_WIDTH } from "../constants";

type Props = { children: React.ReactNode };

export const DesktopSidebar = ({ children }: Props) => {
  const { isAdmin } = useAuth();

  return (
    <div
      className="hidden md:flex md:flex-col md:fixed md:inset-y-0 h-full"
      style={{ width: `${SIDEBAR_WIDTH}px` }}
    >
      <div
        className={clsx(
          "flex flex-col h-full overflow-y-auto bg-slate-800",
          isAdmin ? "pt-16" : "pt-[70px]",
          getScrollbarStyle("page")
        )}
      >
        {children}
      </div>
    </div>
  );
};
