import { gql, useQuery } from "@apollo/client";
import {
  VisitorHeaderQueryQuery,
  VisitorHeaderQueryQueryVariables,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { VisitorBreadcrumbs } from "components/auth/AuthedLayout/components/AuthHeader/components/Breadcrumbs/VisitorBreadcrumbs/VisitorBreadcrumbs";
import { useAuth } from "contexts/AuthProvider";

VisitorHeader.fragments = {
  visitor: gql`
    fragment VisitorsHeader_Visitor on OrganizationVisitor {
      id
      role
      access
      includedEngagementIds
      allEngagementAccess
      user {
        id
        fullName
        pronouns
      }
      engagements {
        id
        name
        endDate
        startDate
        limitedAccessKey
      }
      ...VisitorBreadcrumbs_Visitor
    }
    ${VisitorBreadcrumbs.fragments.visitor}
  `,
};

const VISITOR_HEADER_QUERY = gql`
  query VisitorHeaderQuery($input: GetVisitorInput!) {
    getVisitor(input: $input) {
      id
      ...VisitorsHeader_Visitor
    }
  }
  ${VisitorHeader.fragments.visitor}
`;

type Props = { visitorAccessKey?: string };

export function VisitorHeader({ visitorAccessKey }: Props) {
  const { user } = useAuth();

  const { data } = useQuery<
    VisitorHeaderQueryQuery,
    VisitorHeaderQueryQueryVariables
  >(VISITOR_HEADER_QUERY, {
    fetchPolicy: "network-only",
    skip: !user || !visitorAccessKey,
    variables: {
      input: {
        userId: user!.id,
        limitedAccessKey: visitorAccessKey!,
      },
    },
    onError: (error) => fetchErrToast("your engagement access details.", error),
  });

  return (
    <div className="flex justify-between sticky top-0 z-10 w-full h-16 bg-slate-800 shadow-lg">
      <div className="flex items-center justify-start">
        <VisitorBreadcrumbs
          breadcrumbData={data?.getVisitor}
          visitorAccessKey={visitorAccessKey}
        />
      </div>
    </div>
  );
}
