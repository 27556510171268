import clsx from "clsx";
import { Link, Spinner } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { useState } from "react";
import { AdminMode } from "types/global";
import { SidebarLink } from "../types";

type Props = { links: SidebarLink[] };

export const SidebarLinks = ({ links }: Props) => {
  const { Managing } = AdminMode;
  const [hoveredLink, setHoveredLink] = useState<string | null>(null);
  const { isAdmin, isDashboardMode, isLoadingRoute, setAdminMode } = useAuth();

  const renderAdminSubheadMenuBranches = (active: boolean, index: number) => (
    <div className="flex w-4 h-full border-gray-400">
      {index === 0 && (
        <div className="absolute left-0 w-1 h-[6px] bottom-full border-l" />
      )}
      <div className="absolute w-[14px] border-l border-b top-0 h-1/2" />
      {active && (
        <div className="absolute flex z-[2] h-10 top-[5px] left-[9px] w-[10px] bg-blue-600 rounded-l-md" />
      )}
      {index !== links.length - 1 && (
        <div className="absolute w-1 border-l bottom-0 h-1/2" />
      )}
    </div>
  );

  const renderLink = (link: SidebarLink, index: number) => {
    const isHovered = hoveredLink === link.name;
    const tailwindBgColor = link.current
      ? "bg-slate-700"
      : isHovered
      ? "bg-slate-600"
      : "bg-slate-900";
    const styleBgColor = link.current
      ? "#334155"
      : isHovered
      ? "#475569"
      : "#0f172a";

    return (
      <div
        key={link.name}
        className={clsx(
          "flex relative w-full justify-start items-center h-[50px] ",
          isAdmin && index === 0 && "mt-1"
        )}
        onMouseOver={() => setHoveredLink(link.name)}
        onMouseOut={() => setHoveredLink(null)}
      >
        {isAdmin && renderAdminSubheadMenuBranches(link.current, index)}
        <Link
          route={link.route}
          push={!isDashboardMode}
          routeProps={link.routeProps}
          onClick={() =>
            isDashboardMode && setAdminMode(Managing, false, link.route)
          }
          className={clsx(
            tailwindBgColor,
            "border border-gray-900/10 text-slate-100 group relative",
            "flex w-full font-medium text-sm h-10 rounded-md",
            "align-middle items-center justify-between hover:!no-underline",
            isHovered && !link.current && "border-slate-600"
          )}
        >
          <div
            className={clsx(
              "flex items-center flex-1 h-full pl-3 py-2",
              !link.current &&
                "transform transition-transform duration-[120ms] ease-in-out hover:translate-x-[2px]"
            )}
          >
            <link.icon
              className={clsx(
                "mr-3 flex-shrink-0 h-5 w-5",
                link.current ? "text-white" : "text-gray-100"
              )}
              aria-hidden="true"
            />
            <div className="text-center">{link.name}</div>
          </div>
          {link.current && isLoadingRoute ? (
            <Spinner color="text-gray-400" className="mr-3" />
          ) : (
            link.counter && (
              <link.counter
                className="px-2 py-[1.5px]"
                bgColor={styleBgColor}
              />
            )
          )}
        </Link>
      </div>
    );
  };

  return (
    <nav className="flex flex-col pl-2 pr-3 pb-2">
      {links.map((link, index) => renderLink(link, index))}
    </nav>
  );
};
