import { useSidebarData } from "contexts/SidebarDataProvider";
import pluralize from "pluralize";
import { LiveCountBadge } from "./components/LiveCountBadge";

type Props = { className?: string; bgColor?: string };

export const LiveEngagementsCountBadge = ({ className, bgColor }: Props) => {
  const { liveEngagementsCount } = useSidebarData();

  return (
    <LiveCountBadge
      bgColor={bgColor}
      className={className}
      liveCount={liveEngagementsCount}
      tooltipText={`${liveEngagementsCount || "No"} ${pluralize(
        "engagement",
        liveEngagementsCount
      )} with live cohorts`}
    />
  );
};
