import { clsx } from "clsx";

export const getCornerTriangle = (
  top: boolean,
  left: boolean,
  borderColor = "#334155"
) => {
  let styles = {};
  const size = "12px";

  if (top && left) {
    styles = {
      left: 0,
      bottom: 0,
      borderRight: `${size} solid transparent`,
      borderBottom: `${size} solid ${borderColor}`,
    };
  } else if (top && !left) {
    styles = {
      right: 0,
      bottom: 0,
      borderLeft: `${size} solid transparent`,
      borderBottom: `${size} solid ${borderColor}`,
    };
  } else if (!top && left) {
    styles = {
      top: 0,
      left: 0,
      borderRight: `${size} solid transparent`,
      borderTop: `${size} solid ${borderColor}`,
    };
  } else {
    styles = {
      top: 0,
      right: 0,
      borderLeft: `${size} solid transparent`,
      borderTop: `${size} solid ${borderColor}`,
    };
  }

  return (
    <div
      className="absolute z-[200]"
      style={{ width: 0, height: 0, ...styles }}
    />
  );
};

export const getSidebarCover = (right: boolean, bgColor = "#334155") => (
  <div
    className={clsx(
      "absolute z-[20] top-0 h-full w-1",
      right ? "right-0" : "left-0"
    )}
    style={{ backgroundColor: bgColor }}
  />
);
