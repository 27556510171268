import { useSidebarData } from "contexts/SidebarDataProvider";
import pluralize from "pluralize";
import { LiveCountBadge } from "./components/LiveCountBadge";

type Props = { className?: string; bgColor?: string };

export const LiveCohortsCountBadge = ({ className, bgColor }: Props) => {
  const { liveCohortsCount } = useSidebarData();

  return (
    <LiveCountBadge
      bgColor={bgColor}
      className={className}
      liveCount={liveCohortsCount}
      tooltipText={`${liveCohortsCount || "No"} live ${pluralize(
        "cohort",
        liveCohortsCount
      )}`}
    />
  );
};
