import { AccountRole } from "@generated/graphql";
import { Menu, Transition } from "@headlessui/react";
import { getUserPronounsText } from "@utils/labels";
import { Routes } from "@utils/routes";
import clsx from "clsx";
import { AccountRoleText, Link } from "components/shared";
import { Icon } from "components/shared/Icon";
import { useAuth } from "contexts/AuthProvider";
import { Fragment } from "react";

const userNavigation = (accountRole?: AccountRole) => [
  {
    name: "My Profile",
    route: Routes.myProfile,
    disabled: !(accountRole === AccountRole.TutorTeacher),
  },
  { name: "Settings", route: Routes.myProfile, disabled: true },
];

export const AvatarDropdown = () => {
  const { user, signOut } = useAuth();

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        className={clsx(
          "flex flex-center w-fit h-full cursor-pointer text-sm px-3 py-4 rounded-full"
        )}
      >
        <span className="sr-only">Open user menu</span>
        <Icon icon="profile" size={7} color="text-white" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="divide-y divide-gray-200 origin-top-right absolute right-0 mt-2 w-100 rounded-md shadow-lg bg-white ring-black ring-opacity-5 focus:outline-none !w-fit">
          {user && (
            <div className="py-1">
              <Menu.Item key="accountRole">
                <div className="px-4 py-2">
                  <div className="flex w-fit min-w-[200px]">
                    <Icon
                      size={10}
                      icon="avatar"
                      color="text-slate-600"
                      className="mr-1"
                    />
                    <div className="ml-1">
                      <div className="flex items-center gap-x-[3px] whitespace-nowrap">
                        <div className="text-sm text-gray-700 font-bold py-1">
                          {user.fullName}
                        </div>
                        <p className="font-medium text-gray-500 text-2xs leading-none pt-[1px]">
                          {getUserPronounsText(user.pronouns, "", true)}
                        </p>
                      </div>
                      <div className="text-xs text-gray-700 font-light">
                        {user.email}
                      </div>
                      <AccountRoleText className="text-xs text-gray-700 font-light italic" />
                    </div>
                  </div>
                </div>
              </Menu.Item>
            </div>
          )}

          <div className="pb-1">
            {userNavigation(user?.accountRole).map((item) => (
              <Menu.Item key={item.name} disabled={item.disabled}>
                {({ active }) => (
                  <div
                    className={clsx(
                      "flex w-full h-auto",
                      active ? "bg-gray-100" : ""
                    )}
                  >
                    <Link
                      route={item.route}
                      disabled={item.disabled}
                      className={clsx(
                        item.disabled
                          ? "text-gray-400 cursor-default"
                          : "text-gray-700",
                        "block px-4 py-2 text-sm hover:no-underline",
                        "w-full h-full"
                      )}
                    >
                      {item.name}
                    </Link>
                  </div>
                )}
              </Menu.Item>
            ))}

            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={signOut}
                  className={clsx(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-gray-700 text-sm cursor-pointer"
                  )}
                >
                  Sign out
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
